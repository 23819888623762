@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.top-header {
  background-color: var(--bs-app-bg-color);
}
.card-transition {
  transition: 15s;
}
.padding-2 {
  padding: 2px;
}
.padding-4 {
  padding: 10px;
}

.radius-12 {
  border-radius: 12px !important;
}
.radius-4 {
  border-radius: 4px !important;
}
.radius-3 {
  border-radius: 3px !important;
}

/* ====================Button==================== */
/* Outline Button */
.n-btn-outline {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
}
.n-btn-outline:hover {
  background: rgb(219, 217, 217);
  color: var(--primary);
}
.n-btn-outline i {
  color: var(--primary);
}

/* Outline Button */

body {
  background: #f3f1f2;
  font-family: "Poppins", sans-serif;
}

.ql-editor {
  overflow-y: auto;
  resize: vertical;
  min-height: 100px;
  max-height: 400px;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-auto {
  cursor: auto;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

.bg-white-dark {
  background-color: var(--ni-white-dark);
}

.cursorDown {
  cursor: move;
}

.outline-select {
  outline: 5px solid yellow !important;
}

.css-t3ipsp-control:hover {
  border: 1px !important;
}
.css-1p0o42g-control {
  max-height: 34px !important;
  min-height: 34px !important;
}
.css-q9dids-control {
  max-height: 34px !important;
  min-height: 34px !important;
}
.css-hlgwow {
  margin-bottom: 0px;
}
/* ======Text COLOR */

.ni-text-primary {
  color: var(--primary);
}

.radius-left-0 {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.input-skill {
  outline: 0;
  border-width: 0 0 2px;
  border-bottom: 1px solid rgb(214, 213, 213);
}
.input-skill:focus {
  border-bottom: 1px solid rgb(197, 195, 195);
  outline: 0;
}

.arrow {
  transition: transform 0.5s ease;
}

.rotate {
  transform: rotate(180deg);
}
.quill {
  padding: 0 !important;
}

.lottie-bg {
  height: 98px;
  border-radius: 6px;
  background: linear-gradient(68deg, #ffffff 10%, #e6f9ff 100%);
}

.border-select .react-select__control {
  border: 1px solid var(--bs-gray-300) !important;
}

.border-select .react-select__control:hover {
  border: 1px solid var(--bs-gray-300) !important;
}

.react-select__control:focus {
  background-color: #ffffff !important;
}
