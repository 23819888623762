.n-card-bg {
  background-color: var(--n-card-bg);
}

.n-card-header-bg {
  background-color: var(--n-card-header-bg);
}

.n-bg-primary-opacity-10 {
  background-color: var(--primary-opacity-10);
}
.completed-round {
  background: rgb(232, 255, 243);
  background: linear-gradient(
    230deg,
    rgba(232, 255, 243, 1) 0%,
    rgba(249, 255, 252, 1) 20%,
    rgba(250, 255, 253, 1) 40%,
    rgba(243, 254, 249, 1) 60%,
    rgba(232, 255, 243, 1) 100%
  );
}

.pendding-round {
  background: rgb(255, 249, 227);
  background: linear-gradient(
    230deg,
    rgba(255, 249, 227, 1) 0%,
    rgba(254, 251, 237, 1) 14%,
    rgba(255, 253, 247, 1) 30%,
    rgba(254, 251, 239, 1) 50%,
    rgba(255, 248, 221, 1) 100%
  );
}

.ongoing-round {
  background: rgb(238, 246, 255);
  background: linear-gradient(
    230deg,
    rgba(238, 246, 255, 1) 0%,
    rgba(241, 248, 255, 1) 14%,
    rgba(248, 251, 255, 1) 30%,
    rgba(250, 252, 255, 1) 50%,
    rgba(238, 246, 255, 1) 100%
  );
}

.completed-bg {
  background-color: #50cd89;
}
.pendding-bg {
  background-color: #f6c000;
}
.ongoing-bg {
  background-color: #00c1f6;
}
