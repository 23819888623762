:root {
  --primary: #3e97ff;
  --primary-dark: #0095e8;

  --primary-light: #f3f4f5;
  --bs-page-bg: #2576c7;
  --n-bs-page-bg: var(--primary);
  --bs-app-bg-color: #f3f6f9;
  --n-bg-black-white: #ffffff;
  --danger: #f64e60;
  --danger-dark: #dd4655;
  /* Gray Shade */
  --n-card-bg: #f9fafb;
  --n-card-header-bg: #f9fafb;
  /* Primary color Opacity */
  --primary-opacity-40: #a49ff1;
  --primary-opacity-10: #dddbf9;
  --n-border-color: #e1e3ea;
  --bs-primary: var(--primary);
  --bs-btn-hover-bg: var(--primary-dark);

  --bs-progress-bar-bg: var(--primary);
  --bs-prismjs-btn-color-hover: #fff;
  --bs-secondary: var(--secondary);
  --bs-btn-hover-bg: var(--primary-dark);
  --bs-primary-active: var(--primary-dark);
  --bs-secondary-active: var(--secondary-dark);
  --bs-primary-rgb: 61, 151, 255;
  --bs-text-primary: var(--primary);
  --bs-component-hover-color: var(--primary-dark);
  --bs-component-active-bg: var(--primary-dark);
  --bg-primary-text: #ffffff;
  --menu-item-hover-text: var(--primary);
  /* ====================Button==================== */
  --radius: 4px;
}

[data-bs-theme="light"] {
  --bs-app-bg-color: #f3f6f9;
  --n-bg-black-white: #ffffff;
  --n-back-white: #050f29;
  --ni-white-dark: #ffffff;

  --n-card-bg: #ffffff;
  --n-card-header-bg: #f9fafb;
  --bg-schedule: rgb(255, 255, 255);
  --bg-schedule-gradiant: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(220, 218, 250, 1) 100%
  );
}

[data-bs-theme="dark"] {
  --bs-app-bg-color: #151521;
  --n-bg-black-white: #050505;
  --n-back-white: #ededf3;
  --ni-white-dark: #050505;

  --n-card-bg: #181818;
  --n-card-header-bg: #525455;
  --bg-schedule: rgb(13, 14, 17);
  --bg-schedule-gradiant: linear-gradient(
    0deg,
    rgba(13, 14, 17, 1) 0%,
    rgba(33, 31, 52, 1) 100%
  );
}

.schedul-interview-card-bg {
  background: var(--bg-schedule);
  background: var(--bg-schedule-gradiant);
}
