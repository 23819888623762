.n-menu-icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  margin-right: 0.5rem;
}
.job-stepper-form-container {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
}
.job-stepper-form-field-layout {
  height: calc(85vh - 150px);
  overflow: auto;
}

.no-scroll {
  overflow: hidden;
}
