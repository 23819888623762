/* ====================Primary Filled Button==================== */
.n-primary-filled-btn {
  color: #fff;
  background: var(--primary);
  border: 0;
  border-radius: var(--radius);
}

.n-primary-filled-btn:hover {
  background: var(--primary-dark);
}

.n-primary-filled-btn:focus {
  background: var(--primary-dark);
  outline-width: 0;
}

.n-primary-filled-btn:disabled {
  opacity: 0.65;
}

.n-primary-filled-btn:disabled:hover,
.n-primary-filled-btn:disabled:focus {
  background: var(--primary);
}

/* ====================Primary Outline Button==================== */

.n-primary-outline-btn {
  color: var(--primary);
  border: 1px solid var(--primary) !important;
  border-radius: var(--radius);
  background: none;
}
.n-outline-btn-icon {
  color: var(--primary);
}

.n-primary-outline-btn:focus {
  outline-width: 0;
}

/* ====================Danger Outline Button==================== */

.n-danger-outline-btn {
  color: var(--danger);
  border: 1px solid var(--danger) !important;
  border-radius: var(--radius);
  background: none;
}
.n-outline-danger-btn-icon {
  color: var(--danger);
}

.n-danger-outline-btn:focus {
  outline-width: 0;
}

/* ====================Danger Filled Button==================== */
.n-danger-filled-btn {
  color: #fff;
  background: var(--danger) !important;
  border: 0;
  border-radius: var(--radius);
}
.n-filled-btn-icon {
  color: #fff;
}
.n-danger-filled-btn:hover {
  background: var(--danger-dark) !important;
}

.n-danger-filled-btn:focus {
  background: var(--danger-dark) !important;
  outline-width: 0;
}
/* ====================Gray Filled Button==================== */
.n-gray-filled-btn {
  color: #000;
  background: var(--bs-gray-200);
  border: 0;
  border-radius: var(--radius);
}
.n-filled-gray-btn-icon {
  color: #000000;
}
.n-gray-filled-btn:hover {
  background: var(--bs-gray-300);
}

.n-Gray-filled-btn:focus {
  background: var(--bs-gray-500) !important;
  outline-width: 0;
}
