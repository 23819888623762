.fs-20 {
  font-size: 20px;
}
.fs-8px {
  font-size: 8px;
}
.fs-12px {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-24 {
  font-size: 24px;
  line-height: 40px;
}
.fs-28 {
  font-size: 28px;
  line-height: 40px;
}
.fs-32 {
  font-size: 32px;
  line-height: 40px;
}
.fs-40 {
  font-size: 40px;
  line-height: 50px;
}
/* Radius */
.radius-12 {
  border-radius: 12px;
}

.radius-8 {
  border-radius: 8px;
}
.w-280px {
  width: 280px;
}
